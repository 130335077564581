<template>
  <div class="card-title">
    <div class="left">
      <i :class="'iconfont ' + titleIcon" :style="{color: titleIconColor}" style="color:#409EFF;margin-right:3px;font-size: 18px;"></i>
      <div class="title"> {{title}} </div>
    </div>
    <div class="more" @click="onClickMore">
      <div class="more-text"> 更多 </div>
      <i class="iconfont icon-more-dot" style="color:#409EFF;margin-right:3px;font-size: 18px;"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题'
    },
    titleIcon: {
      type: String,
      default: '', // icon-iconfont-iconfontzhan
    },
    titleIconColor: {
      type: String,
      default: '#409EFF'
    },
    moreIcon: {
      type: String,
      default: 'icon-more-vertical'
    }
  },
  methods: {
    onClickMore(){
      this.$emit('more')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-title{
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    align-items: center;
    .title{
      margin-left: 3px;
      font-size: 18px;
      font-weight: bold;
      font-weight: 550;
      // color: #606266;
      // color: #333;
      // color: #303133;
      color: #404246;
    }
  }
  .more{
    display: flex;
    align-items: center;
    cursor: pointer;
    .more-text{
      font-size: 14px;
      color: #409EFF;
      color: rgb(64, 158, 255);
      // font-weight: 550;
    }
  }
}
</style>