<template>
  <div class="home-index">
    <!-- <div class="echart-preview">
      <rows-card></rows-card>
    </div> -->
    <!-- <gl-card>
      <rows-card></rows-card>
    </gl-card> -->

    <gl-card>
      <chart-top
        title="销量金额"
        @click-change="onTimeChange('saleroom', $event)"
        :type.sync="saleroomType"
      >
      </chart-top>
      <homeChart :chartData="saleroomData" :type="saleroomType" />
    </gl-card>
    <gl-card>
      <chart-top
        title="销量数量"
        @click-change="onTimeChange('count', $event)"
        :type.sync="countType"
      >
      </chart-top>
      <homeChart :chartData="countData" :type="countType" />
    </gl-card>
    <gl-card>
      <chart-top
        title="邀请人数"
        @click-change="onTimeChange('invited', $event)"
        :type.sync="invitedType"
      >
      </chart-top>
      <homeChart :chartData="invitedData" :type="invitedType" />
    </gl-card>

    <gl-card>
      <card-title
        title="商品销量排行"
        titleIcon="icon-vertical-chart-yellow"
        titleIconColor="#fd8d2c"
        @more="$router.push('/main/cool/index')"
      >
      </card-title>
      <el-table :data="resList" style="width: 100%">
        <el-table-column
          type="index"
          :index="1"
          label="排名"
          align="center"
          width="44"
        ></el-table-column>
        <el-table-column
          prop="date"
          label="商品名称"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="下单笔数"
          width="180"
        ></el-table-column>
        <el-table-column prop="name" label="销售总额"></el-table-column>
      </el-table>
    </gl-card>
  </div>
</template>

<script>
import chartTop from '../../../components/chart-top.vue'
import homeChart from '../../../components/home-chart.vue'
import rowsCard from '../../../components/rows-card.vue'
import cardTitle from '../../../components/card-title.vue'
import { getOrderSum, getOrderCount, getFans } from '../../../api/echart.js'

export default {
  name: 'Home',
  components: {
    chartTop,
    homeChart,
    rowsCard,
    cardTitle,
  },
  data() {
    return {
      saleroomType: 'line',
      saleroomData: {}, //销售额
      countType: 'line',
      countData: {}, //销量数量
      invitedType: 'line',
      invitedData: {}, //邀请人数
      chartFun: {
        saleroom: this.loadSaleroomList,
        count: this.loadCountList,
        invited: this.loadInvitedList,
      },
      chartForm: {
        saleroom: {
          days: '1',
        },
        count: {
          days: '1',
        },
        invited: {
          days: '1',
        },
      },

      resList: [
        // {key: 1, name: 'test', date: '20222'},
        // {key: 2, name: 'test', date: '20222'},
        // {key: 3, name: 'test', date: '20222'},
        // {key: 4, name: 'test', date: '20222'},
        // {key: 5, name: 'test', date: '20222'},
        // {key: 6, name: 'test', date: '20222'},
        // {key: 7, name: 'test', date: '20222'},
        // {key: 8, name: 'test', date: '20222'},
        // {key: 9, name: 'test', date: '20222'},
        // {key: 10, name: 'test', date: '20222'},
      ],
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    loadChart() {
      this.loadCountList()
      this.loadSaleroomList()
      this.loadInvitedList()
    },
    // 销售金额
    async loadSaleroomList() {
      const params = { queryType: this.chartForm['saleroom'].days || 1 }
      const result = await getOrderSum(params)
      const { xData: xData1, yData: yData1 } = this.extractData(
        result,
        'orderSumDate',
        'orderSum'
      )
      const res = {
        name: '销售额',
        type: this.saleroomType,
        xData: xData1,
        yData: yData1,
        series: [],
      }
      this.saleroomData = res
    },
    async loadCountList() {
      console.log('loadCountList')
      const params = { queryType: this.chartForm['count'].days || 1 }
      const result = await getOrderCount(params)
      const { xData: xData2, yData: yData2 } = this.extractData(
        result,
        'orderCountDate',
        'orderCount'
      )
      const res = {
        name: '销售数量',
        type: this.countType,
        xData: xData2,
        yData: yData2,
        series: [],
      }
      this.countData = res
    },

    async loadInvitedList() {
      console.log('loadInvitedList')
      const params = { queryType: this.chartForm['invited'].days || 1 }
      const result = await getFans(params)

      const { xData: xData3, yData: yData3 } = this.extractData(
        result,
        'fansDate',
        'fansNum'
      )
      const res = {
        name: '邀请人数',
        type: this.invitedType,
        xData: xData3,
        yData: yData3,
        series: [],
      }
      this.invitedData = res
    },
    onTimeChange(chartType, item) {
      const { type, startTime, endTime, dateTime } = item

      const loadList = this.chartFun[chartType]

      this.chartForm[chartType].days = type
      console.log(item)

      if (type == 'custom') {
        if (!startTime && !endTime) return
        console.error('自定义选项时间:', dateTime)
        this.chartForm[chartType].startTime = startTime
        this.chartForm[chartType].endTime = endTime
      } else {
        this.chartForm[chartType]['startTime'] &&
          delete this.chartForm[chartType]['startTime']
        this.chartForm[chartType]['endTime'] &&
          delete this.chartForm[chartType]['endTime']
      }

      loadList && loadList()
    },

    // 数据解构赋值
    extractData(response, dateKey, valueKey) {
      // 首先，我们检查响应是否有效，并且是否包含我们需要的数据
      if (response && response.code === 200 && Array.isArray(response.data)) {
        // 使用 map 函数从数据数组中提取我们需要的值
        const xData = response.data.map((item) => item[dateKey])
        const yData = response.data.map((item) => item[valueKey])

        // 返回解构的数据
        return { xData, yData }
      } else {
        // 如果响应无效或数据缺失，我们返回一些默认的空数组
        console.error('Invalid response:', response)
        return { xData: [], yData: [] }
      }
    },
  },
}
</script>

<style lang="less" scoped>
// .header-nav{}
.old-content {
  text-align: center;
}
.echart-preview {
  margin: 18px;
  padding: 10px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>
