<template>
  <div class="rows-card">
    <el-row class="row-list" :gutter="36">
      <el-col v-for="(item,index) of newList" :key="index" :span="item.span">
        <div class="row-item">
          <div class="row-item_box">
            <div class="item-number"> {{item.value}} </div>
            <div class="item-name"> {{item.name}} </div>
          </div>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div class="row-item">
          <div class="row-item_box">
            <div class="item-number"> 1 </div>
            <div class="item-name"> 1-1 </div>
          </div>
        </div>
      </el-col> -->
      <!-- <el-col :span="6" v-for="(elem) of subList" :key="elem.value">
        <div class="row-item">
          <div class="row-item_box">
            <div class="item-number"> {{elem.value}} </div>
            <div class="item-name"> {{elem.name}} </div>
          </div>
        </div>
      </el-col> -->
      
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'rowsCard',
  props: {
    gutter: {
      type: Number,
      default: 24,  // !36
    },
    span: {
      type: Number,
      default: 8,  // !4    后4-span:6
    },
  },
  computed:{
    newList(){
      return this.list
    },
    subList(){
      return [
        {name: '用户提现申请', value: 0, span: 6},
        {name: '用户提现待打款', value: 95, span: 6},
        {name: '结算申请', value: 0, span: 6},
        {name: '结算待打款', value: 0, span: 6},
        {name: '所有用户总余额', value: 2581.93, span: 6},
      ]
    },
  },
  data(){
    return {
      list: [
        {name: '待支付订单', value: 10, span: 4, background: null},
        {name: '待发货订单', value: 20, span: 4, background: '#FFA3A1'},
        {name: '待收货订单', value: 120, span: 4},
        {name: '已成交订单', value: 110, span: 4},
        {name: '商品上架申请', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},
        {name: 'xxxx', value: 1, span: 4},

        {name: '用户提现申请', value: 0, span: 6},
        {name: '用户提现待打款', value: 95, span: 6},
        {name: '结算申请', value: 0, span: 6},
        {name: '结算待打款', value: 0, span: 6},
        {name: '所有用户总余额', value: 2581.93, span: 8},
        {name: '所有用户总余额', value: 2581.93, span: 8},
        {name: '所有用户总余额', value: 2581.93, span: 8},

        {name: '所有用户总余额', value: 2581.93, span: 12},
        {name: '所有用户总余额', value: 2581.93, span: 12},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
// 基础颜色
$saleColor:#FFA3A1;
$taxColor:#84d9d2;
$extenedColor:#87DE75;
$likeColor:#a5e7f0;
$linkColor:#93b7e3;
$keleColor:#edafda;

$--card-bg: #6795ff;
// background: linear-gradient(180deg,#5c8dff,#6795ff);
.rows-card{
  .row-list{
    box-sizing: border-box;
    .row-item{
      text-align: center;
			font-size: 14px;
      border-radius: 6px;
      // padding: 10px;
      padding: 10px 0;
			color:#fff;
			height: 80px;

      &_box{
        background: #84d9d2;
        height: 100%;
        border-radius: 6px;
        text-align: center;
        
        .item-number{
          padding-top: 15px;
          font-size: 22px;
				  font-weight: bold;
        }
        .item-name{
          font-size: 13px;
          color: #efefef;
        }
      }   

    }
  }
}
</style>