/*
 * @Descripttion: 首页图表接口
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-05-26 10:24:53
 * @LastEditors: pj
 * @LastEditTime: 2023-05-26 11:30:13
 */
import request from '@/plugins/request'


//聊天用户列表
export function getOrderSum (params = {}) {
  return request({
    url: '/api/order/parent/getOrderSum',
    method: 'GET',
    params: params
  })
}


export function getOrderCount (params = {}) {
  return request({
    url: '/api/order/parent/getOrderCount',
    method: 'GET',
    params: params
  })
}

export function getFans (params = {}) {
  return request({
    url: '/api/user/info/getFans',
    method: 'GET',
    params: params
  })
}